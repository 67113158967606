import axios from "axios";
require('dotenv').config();

function generarCarga(type, bucketPrivado) {
  return callAPI("get", { type: type, bucketPrivado: bucketPrivado });
}

function moverArchivo(payload) {
  return callAPI("upsert", payload);
}

async function procesarCarga(archivo, bucketPrivado, cbProgreso) {
  return new Promise(async (resolve, reject) => {
    try {
      let datos = await generarCarga(archivo.type, bucketPrivado);
      const resultadoGenerarCarga = datos.data;
      if (resultadoGenerarCarga === null)
        throw new Error("Error al generar carga");
      //
      const form = new FormData();
      Object.keys(resultadoGenerarCarga.fields).forEach((key) => {
        form.append(key, resultadoGenerarCarga.fields[key]);
      });

      form.append("file", archivo);
      //
      axios({
        method: "post",
        url: resultadoGenerarCarga.url,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (e) => {
          const progreso = e.loaded / e.total;
          cbProgreso(progreso);
        },
        data: form,
      })
        .then((r) => {
          if (r.status === 201) {
            resolve({
              bucket: resultadoGenerarCarga.fields.bucket,
              key: resultadoGenerarCarga.fields.key,
            });
          } else {
            throw new Error("Error al cargar archivo");
          }
        })
        .catch((e) => {
          if (e.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(e.response.data);
            console.log(e.response.status);
            console.log(e.response.headers);
          } else if (e.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(encodeURI.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", e.message);
          }
          console.log(e.config);
          reject(e);
        });
    } catch (e) {
      console.log("......>e", e);
      reject(e);
    }
  })
    .then((r) => {
      return r;
    })
    .catch((e) => {
      console.log("===>e", e);
      return null;
    });
}

function callAPI(accion, payload) {
  return new Promise(async (resolve, reject) => {
    axios({
      method: "post",
      url: process.env.REACT_APP_UPLOAD_FILE + accion,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(payload),
    })
      .then((result) => {
        if (result.status === 200) {
          if (result.data.status === 200) {
            resolve(result.data);
          } else {
            resolve(false);
          }
        } else {
          reject(result);
        }
      })
      .catch((e) => {
        console.log("ERROR => ", e);
        reject(e);
      });
  });
}

export default {
  generarCarga,
  procesarCarga,
  moverArchivo,
};

import axios from "axios"
require('dotenv').config();

//------------------------Consultas a la API-------------------------------
async function callAPI(message, conversationId, url, promo) {
    const data = {
        message,
        conversationId,
        url,
        promo
    };
    const api = process.env.REACT_APP_API_SOLICITUD_LEX;
  
    return new Promise(async (resolve, reject) => {
        try {
            axios({
                method: "post",
                url: api,
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(data),
            })
                .then((result) => {
                    if (result.status === 200) {
                        resolve(result);
                    } else {
                        reject(result);
                    }
                })
                .catch((e) => {
                    console.log("ERROR => ", e);
                    reject(e);
                });
        } catch (error) {
            console.log("error al consumir API: ", error)
        }
    });
}


export default callAPI

